import React, { useState, useEffect } from 'react'
import Spinner from '../components/Spinner'
import { useStateContext } from '../context/ContextProvider'
import Editor from '../components/Editor';
import BuyCreditsModal from '../components/BuyCreditsModal'
import { db, storage } from '../firebase'
import { getDoc, updateDoc, doc, addDoc, setDoc, collection } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'

const Embroidery = () => {

  const { embroidery, setEmbroidery, credits, setCredits, currentUser, fetchDashboardData } = useStateContext()
  const [loading, setLoading] = useState()
  const [disabled, setDisabled] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  //credits modal
  const [modalIsOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("")
  //Uploading
  const [isUploading, setIsUploading] = useState(false)
  //saved popup
  const [isSaved, setIsSaved] = useState(false)

  function handleCloseModal(e) {
    setIsOpen(false);
  }

  useEffect(() => {
    async function getCredits() {
      const docRef = doc(db, "credits", currentUser.uid)
      const data = await getDoc(docRef)
      setCredits(data.data().credit)
    }
    getCredits()
  }, [])

  const handleSubmit = async (event) => {
    try {
      event.preventDefault()

      if (credits <= 0) {
        setIsOpen(true)
        setDisabled(false)
        setLoading(false)
        return
      }

      setEmbroidery(null)
      setDisabled(true)
      setLoading(true)
      console.log("Started")

      const design = event.target.elements.design.value;
      const name = design + " - Embroidery"
      setName(name)
      const prompt = design;
      console.log(prompt)
      const response = await fetch(`https://backend.mypodbot.com/generate_embroidory/?prompt=${prompt}`)
      function blobToBase64(blob) {
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      }

      const resultImage = await response.blob()
      const imageUrl = await blobToBase64(resultImage)
      setEmbroidery(imageUrl)

      const docref = doc(db, "credits", currentUser.uid)
      await updateDoc(docref, { credit: credits - 1 })
      setCredits(credits - 1)

      console.log("Finished!")
    }
    catch (err) {
      alert("Something went wrong, please try again!")
    }
    setLoading(false)
    setDisabled(false)
  }

  const handleSave = async () => {
    setIsUploading(true)
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        console.log(e);
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", embroidery, true);
      xhr.send(null);
    });

    var date = new Date()
    var time = date.getTime()
    console.log(date)

    const docRef = doc(db, 'images', currentUser.uid)

    const uploadRef = ref(storage, `/${currentUser.uid}/${time}`)
    await uploadBytes(uploadRef, blob).then(async (snapshot) => {
      const downloadUrl = await getDownloadURL(uploadRef);
      await setDoc(docRef, {
        [time]: {
          name: name,
          imageUrl: downloadUrl,
          date: date,
          id: time
        }
      }, { merge: true })
      console.log("Uploaded a blob or file")
      fetchDashboardData()
      setIsSaved(true)
      setTimeout(() => {
        // After 3 seconds set the show value to false
        setIsSaved(false)
      }, 2000)
    })

    setIsUploading(false)

  }

  const initialDesign = "h-[540px] md:h-[740px]"

  return (
    <div className=' my-24 px-6 md:my-12 md:pl-12 w-full h-full'>
      <h1 className='text-white text-3xl font-medium'>Embroidery</h1>
      <div className='grid grid-cols-1 lg:grid-cols-2 w-full lg:gap-10 gap-6 mt-10'>
        <div>
          <form onSubmit={handleSubmit} className="flex flex-col">
            <input type="text" name="design" class="mb-4 bg-block-bg border border-light-gray text-white  text-sm rounded-lg active:border-light-gray focus:border-transparent block w-full p-2.5 " placeholder="Enter Design" required />
            <div>
              <button type="submit" disabled={disabled} className='mb-4 bg-active-blue px-3 py-2 text-white font-medium rounded-lg hover:bg-black transform transition-all duration-50 disabled:bg-gray-800 disabled:cursor-not-allowed'>
                Generate
              </button>
            </div>
            <div class="flex p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300" role="alert">
              <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">Info</span>
              <div>
                Embroidery Generation is still under development and might have some low quality outputs!
              </div>
            </div>

          </form>
        </div>
        <div className={`bg-canvas-bg rounded-xl flex flex-col justify-center items-center p-6 ${embroidery ? "" : initialDesign}`}>
          {loading ?
            <Spinner /> :
            embroidery ?
              <>
                <div className='rounded-lg relative'>
                  {isUploading ? <div className='h-full w-full absolute bg-black rounded-lg text-white flex flex-col justify-center items-center opacity-60'><Spinner className="opacity-100" status="Uploading.." /></div> : ""}
                  <img src={embroidery} className="rounded-lg max-h-[700px]" alt="Art Print from the prompt" />
                </div>

                <div className='gap-x-2 flex'>
                  <a href={embroidery} download className='text-decoration-none text-white mt-5 bg-download-green px-2 py-2 rounded-lg font-medium transform transition-all duration-50 text-black hover:bg-black hover:text-white'>Download</a>
                  <button onClick={() => { setModalOpen(true) }} className='text-decoration-none mt-5 px-2 py-2 rounded-lg font-medium bg-[#FFCA28] hover:bg-black hover:text-white'>Edit</button>
                  <button onClick={() => { handleSave() }} className='text-decoration-none mt-5 px-2 py-2 rounded-lg font-medium bg-[#4FC3F7] hover:bg-black hover:text-white'>{isSaved ? "Saved!" : "Save"}</button>
                </div>
              </>
              : ""}
        </div>
      </div>

      {modalOpen &&
        <div id="defaultModal" tabindex="-1" aria-hidden="true" class="bg-black bg-opacity-50 fixed top-20 left-0 right-0 md:items-center md:flex md:m-auto justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
          <div class="relative w-full h-full max-w-4xl md:h-auto">
            <div class="relative bg-sidebar-bg rounded-lg shadow dark:bg-gray-700">
              <div class="flex items-start justify-between p-4 rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold text-white">
                  PODBot Editor
                </h3>
                <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                  <svg onClick={() => { setModalOpen(false) }} aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  <span class="sr-only">Close modal</span>
                </button>
              </div>
              <div>
                <Editor image={embroidery} method={setEmbroidery} />
              </div>
            </div>
          </div>
        </div>}

      <BuyCreditsModal
        IsModalOpened={modalIsOpen}
        onCloseModal={handleCloseModal} />


    </div>
  )
}

export default Embroidery