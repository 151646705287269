import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStateContext } from '../context/ContextProvider'
import Editor from '../components/Editor'
import { doc, updateDoc, getDoc, deleteField } from 'firebase/firestore'
import { db, storage } from '../firebase'
import { ref, deleteObject } from 'firebase/storage'


const SingleImage = () => {

    const location = useLocation()
    const pathId = location.pathname.slice(1)
    const { currentUser, fetchDashboardData } = useStateContext()
    const [index, setIndex] = useState()
    const[image, setImage] = useState()
    const[name, setName] = useState()
    const[modalOpen,setModalOpen] = useState(false)
    const[deleteModalOpen, setDeleteModalOpen] = useState(false)
    const navigate = useNavigate();

    // const download = async (e) => {

    //   function blobToBase64(blob) {
    //     return new Promise((resolve, _) => {
    //       const reader = new FileReader();
    //       reader.onloadend = () => resolve(reader.result);
    //       reader.readAsDataURL(blob);
    //     });
    //   }

    //   const response = await fetch(image).then(async (res)=>{
    //     console.log(res.type)
    //     const resultImage = await res.blob()
    //     const imageUrl = await blobToBase64(resultImage)
    //     setImage(imageUrl)
    //   })

    // };

    useEffect(async () => {
        const docRef = doc(db,'images', currentUser.uid)
        const data = await getDoc(docRef)
        const dataObj = data.data()
        // setImage(dataObj[pathId.toString()].imageUrl)
        setName(dataObj[pathId.toString()].name)

        function blobToBase64(blob) {
          return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
        }
  
        const response = await fetch(dataObj[pathId.toString()].imageUrl).then(async (res)=>{
          console.log(res.type)
          const resultImage = await res.blob()
          const imageUrl = await blobToBase64(resultImage)
          setImage(imageUrl)
        })
    }, [])

    async function handleDelete(id) {
      const docRef = doc(db, 'images', currentUser.uid)
      const storageRef = ref(storage, `/${currentUser.uid}/${id}`)
      console.log(id)
      await deleteObject(storageRef).then(() => {
        console.log("File Deleted Successfully")
      })
      await updateDoc(docRef, {
        [id]: deleteField()
      })
      console.log("Deleted")
      await fetchDashboardData()
      navigate("/myDesigns")

    }

    return (
        <div className=' my-24 px-6 md:my-12 md:pl-12 w-full h-full'>
            <div className=''>
                <h1 className='text-white text-3xl font-medium'>{name}</h1>
                <div className='flex rounded-lg justify-center bg-sidebar-bg w-full mt-10 p-10'>
                    <div className='grid grid-cols-3 lg:grid-cols-4 gap-6'>
                        <div className='col-span-3 md:col-span-2 lg:col-span-3 bg-black bg-opacity-20 rounded-lg'>
                            <img className='rounded-lg' src={image}></img>
                        </div>
                        <div className='gap-x-2 col-span-3 md:col-span-1 flex flex-col text-center gap-y-2'>
                          <a href={image} download className=' text-decoration-none text-white mt-5 bg-download-green px-2 py-2 rounded-lg font-medium transform transition-all duration-50 text-black hover:bg-black hover:text-white'>Download</a>
                          <button onClick={() => { setModalOpen(true) }} className='text-decoration-none text-white bg-active-blue px-2 py-2 rounded-lg font-medium hover:bg-black'>Edit</button>
                          <button onClick={() => setDeleteModalOpen(pathId)} className='bg-red-800  text-white p-2 rounded-lg hover:bg-gray-800'>Delete</button>
                        </div>
                    </div>
                </div>
            </div>

            {modalOpen &&
        <div id="defaultModal" tabindex="-1" aria-hidden="true" class="bg-black bg-opacity-50 fixed top-20 left-0 right-0 md:items-center md:flex md:m-auto justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
          <div class="relative w-full h-full max-w-4xl md:h-auto">
            <div class="relative bg-sidebar-bg rounded-lg shadow dark:bg-gray-700">
              <div class="flex items-start justify-between p-4  rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold bg-siderbar-bg text-white">
                  PODBot Editor
                </h3>
                <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                  <svg onClick={() => { setModalOpen(false) }} aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  <span class="sr-only">Close modal</span>
                </button>
              </div>
              <div>
                <Editor image={image} method={setImage} />
              </div>
            </div>
          </div>
        </div>}

        {deleteModalOpen &&
        <div id="popup-modal" tabIndex={"-1"} className="bg-black bg-opacity-50 fixed top-20 left-0 right-0 md:items-center md:flex md:m-auto justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
        <div className="relative w-full h-full max-w-md md:h-auto">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-hide="popup-modal" onClick={() => setDeleteModalOpen(false)}>
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    <span className="sr-only">Close modal</span>
                </button>
                <div className="p-6 text-center">
                    <svg aria-hidden="true" className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap='round' strokeLinejoin='round' strokeWidth={"2"} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete the image?</h3>
                    <button data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2" onClick={() => handleDelete(pathId)}>
                        Yes
                    </button>
                    <button data-modal-hide="popup-modal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={() => setDeleteModalOpen(false)}>No, cancel</button>
                </div>
            </div>
        </div>
    </div>}
        </div>
  )
}

export default SingleImage