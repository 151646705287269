import React, { useEffect, useState } from 'react'
import { db, storage } from '../firebase'
import {ref, listAll, getDownloadURL, uploadBytes} from 'firebase/storage'
import { useStateContext } from '../context/ContextProvider'
import img1 from '../data/temp_data/download2.png'
import { addDoc, doc,serverTimestamp,setDoc, updateDoc, collection, getDoc } from 'firebase/firestore'

const FirebaseStorageTrial = () => {

    const {currentUser} = useStateContext()
    const[image, setImage] = useState()
    const[val, setVal] = useState(null)
    const[imageName, setName] = useState([])
    const[imageUrl, setImageUrl] = useState([])
    const[imageDate, setImageDate] = useState([])

    const listRef = ref(storage, currentUser.uid)
    const metadata = {
        contentType: 'image/png',
        name:"UploadFile",
        generationTime:"12th Dec"
      };

    useEffect(()=>{
        setImage(img1)
    },[])

    const uploadImageTops = async () => {
        const blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function (e) {
            console.log(e);
            reject(new TypeError("Network request failed"));
          };
          xhr.responseType = "blob";
          xhr.open("GET", image, true);
          xhr.send(null);
        });

        var date = new Date().getTime()
        console.log(date)
        const docval ={
            name:"Hello",
        }
        // const docRef = await addDoc(collection(db,`images/${currentUser.uid}/${date}`), {
        //     name:"Sticker",
        //   });

        const docRef = doc(db,'images',currentUser.uid)
        await setDoc(docRef,{
            [date]:{
                name:"Hello"
            }
        }, { merge: true})

        const uploadRef = ref(storage,`/${currentUser.uid}/${date}`)
        await uploadBytes(uploadRef, blob, metadata).then(async (snapshot)=>{
            const downloadUrl = await getDownloadURL(uploadRef);
            await setDoc(docRef,{
                [date]:{
                    name:"Hello",
                    imageUrl:downloadUrl,
                }
            }, { merge: true})
            console.log("Uploaded a blob or file")
        })

    }


    async function fetchAll(){
        const docRef = doc(db,'images', currentUser.uid)
        const data = await getDoc(docRef)
        const dataObj = data.data()
        await setVal(dataObj)
        Object.keys(dataObj).forEach(function(key, index){
            // console.log(dataObj[key)
            var obj = dataObj[key]
            setName(oldName => [...oldName, obj.name])
            setImageUrl(oldImageUrl => [...oldImageUrl, obj.imageUrl])
            setImageDate(oldDate => [...oldDate, obj.date['seconds']])
            // console.log(obj.name)
            // console.log(obj.imageUrl)
            // console.log(obj.date['seconds'])
        })
        
    }

    // useEffect(async () =>{
    //     const docRef = doc(db,"images", currentUser.uid)
    //     const data = await getDoc(docRef)
    //     console.log(data.data())
    // },[])

      

    // useEffect( () =>{
    //     listAll(listRef)
    //     .then((res) =>{
    //         const divElement = document.getElementById("container-div")
    //         res.items.forEach((itemRef) =>{
    //             console.log(itemRef)
    //             let img = document.createElement("img")
    //             getDownloadURL(itemRef).then((url) =>{
    //                 img.src=url
    //                 divElement.appendChild(img)
    //             })
    //         })
    //     })
    // }, []) 

    function download(){
        listAll(listRef)
        .then((res) =>{
            const divElement = document.getElementById("container-div")
            res.items.forEach((itemRef) =>{
                console.log(itemRef)
                let img = document.createElement("img")
                getDownloadURL(itemRef).then((url) =>{
                    img.src=url
                    divElement.appendChild(img)
                })
            })
        })
    }

    function upload(){
        const uploadRef = ref(storage,`/${currentUser.uid}/uploadedImage`)
        uploadBytes(uploadRef, img1, metadata).then((snapshot)=>{
            console.log("Uploaded a blob or file")
        })
    }

    function clear(){
        var div = document.getElementById("container-div")
        while(div.firstChild){
            div.removeChild(div.firstChild)
        }
    }


  return (
    <div className='container-div gap-6 space-x-6 flex flex-col'>
        {/* <img id="image"></img> */}
        <button className='bg-white p-2 rounded-lg' onClick={download}>Download</button>
        <button className='bg-white p-2 rounded-lg' onClick={uploadImageTops}>Upload</button>
        <button className='bg-white p-2 rounded-lg' onClick={clear}>Clear</button>
        <button className='bg-white p-2 rounded-lg' onClick={fetchAll}>Fetch</button>
        <div className='py-6 h-[600px] overflow-y-auto'>
                {imageName ? imageName.map((val, index) => (
                  <div key={index} className='h-[100px] py-4 mx-auto bg-[#37394D] rounded-xl shadow-lg flex items-center mb-6 transform transition duration-100 hover:bg-black cursor-pointer'>
                    <div className='flex relative px-4 space-x-4'>
                      <div className="h-[75px] w-[75px] relative bg-no-repeat bg-cover bg-center rounded-xl h-full rounded-lg" style={{ backgroundImage: `url(${imageUrl[index]})` }}></div>
                      {/* <img src={item.image} className='h-[75px] rounded-lg' /> */}
                      <div className='flex flex-col'>
                        <h1 className='text-white font-medium text-lg mb-2'>{val}</h1>
                        <p className='text-sm text-[#6B6D7F] font-medium'>{imageDate[index]}</p>
                      </div>
                    </div>
                  </div>
                )):""}
              </div>

        {/* <img src={image}></img> */}

        

    </div>
  )
}

export default FirebaseStorageTrial