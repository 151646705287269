import React,{useEffect} from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import {FiSettings} from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import {Button, Cart, Footer, Header, Navbar, Notifications, Sidebar, UserProfile, Product, Trial, FirebaseStorageTrial} from './components';
import {Amazon, Analytics, Etsy, UploadAPI, Dashboard, ResearchAPI, MyDesigns, Printful, Settings, ArtPrint, Embroidery, Stickers, Login, SignUp, Seo, SingleImage, Privacy, ForgotPassword, EmailVerification, AllOverPrint} from './pages';
import './App.css'
import { useStateContext } from './context/ContextProvider';
import PrivateRoute from './PrivateRoute';
import Editor from './components/Editor';
import ReactGA from 'react-ga4'

const TRACKING_ID = "G-14Y1L9ZC7M";//Google Analytics Tracking ID
ReactGA.initialize([{trackingId: TRACKING_ID}])


const App = () => {

  const {activeMenu, isLoggedIn, currentUser} = useStateContext();
  
  useEffect(() => {
    ReactGA.send({hitType:"pageview", page: window.location.pathname + window.location.search});
    console.log(window.location.pathname + window.location.search)
  }, [window.location.pathname + window.location.search]);

  return (
    <div>
      <BrowserRouter>
        <div className='flex relative  dark:bg-main-dark-bg'>
          {/* <div className='fixed right-4 bottom-4' style={{zIndex:"1000"}}>
            <TooltipComponent content="Settings" position='Top'>
              <button type='button' className='text-3xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white'
                style={{background:'blue', borderRadius:"50%"}}
              >
                <FiSettings />
              </button>
            </TooltipComponent>
          </div> */}
          {currentUser && 
          activeMenu ? (
            <div className='w-72 fixed sidebar dark"bg-secondary-dark-bg bg-white'>
              < Sidebar />
            </div>)
            : (
              <div className='w-0 dark:bg-secondary-dark-bg'>
                < Sidebar />
              </div>
            ) 
          }
            <div className={`dark:bg-main-bg bg-main-bg min-h-screen w-full ${currentUser && activeMenu ? 'md:ml-72' : 'flex-2'}`}>
              <div className='fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full'>
                {currentUser? <Navbar /> : ""}
              </div>
              <div>
              <Routes>
                {/* Menu */}
                {/* <Route exact path="/" element={<Navigate replace to="/mydesigns" />} /> */}
                {/* <Route exact path="/" element={<PrivateRoute />}>
                  <Route exact path="/" element={<MyDesigns />} />
                </Route> */}

                <Route exact path="/" element={ <PrivateRoute> <Navigate replace to="/dashboard" /> </PrivateRoute>}> </Route>
                <Route exact path="/dashboard" element={ <PrivateRoute> <Dashboard /> </PrivateRoute>}> </Route>
                <Route exact path="/seo" element={ <PrivateRoute> <Seo /> </PrivateRoute>}> </Route>
                <Route exact path="/mydesigns" element={ <PrivateRoute> <MyDesigns /> </PrivateRoute>}> </Route>
                <Route exact path="/analytics" element={ <PrivateRoute> <Analytics /> </PrivateRoute>}> </Route>

                {/* <Route path="/dashboard" element={<Dashboard />}/>
                <Route path="/mydesigns" element={<MyDesigns />}/>
                <Route path="/analytics" element={<Analytics />}/> */}

                {/* My Shops */}
                {/* <Route path="/amazon" element={<Amazon />}/>
                <Route path="/etsy" element={<Etsy />}/> */}
                <Route exact path="/amazon" element={ <PrivateRoute> <Amazon /> </PrivateRoute>}> </Route>
                <Route exact path="/etsy" element={ <PrivateRoute> <Etsy /> </PrivateRoute>}> </Route>

                {/* API Integrations */}
                {/* <Route path="/printful" element={<Printful />}/>
                <Route path="/merchInformer" element={<MerchInformer />}/>
                <Route path="/uploadAPI" element={<UploadAPI />}/> */}
                <Route exact path="/printful" element={ <PrivateRoute> <Printful /> </PrivateRoute>}> </Route>
                <Route exact path="/researchAPI" element={ <PrivateRoute> <ResearchAPI /> </PrivateRoute>}> </Route>
                <Route exact path="/uploadAPI" element={ <PrivateRoute> <UploadAPI /> </PrivateRoute>}> </Route>

                {/* Products */}
                {/* <Route path='/product' element={<Product />}/>
                <Route path='/artPrints' element={<ArtPrint />}/>
                <Route path='/stickers' element={<Stickers />}/>
                <Route path='/embroidery' element={<Embroidery />}/> */}
                <Route exact path="/artPrints" element={ <PrivateRoute> <ArtPrint /> </PrivateRoute>}> </Route>
                <Route exact path="/stickers" element={ <PrivateRoute> <Stickers /> </PrivateRoute>}> </Route>
                <Route exact path="/embroidery" element={ <PrivateRoute> <Embroidery /> </PrivateRoute>}> </Route>
                <Route exact path="/alloverPrint" element={ <PrivateRoute> <AllOverPrint/> </PrivateRoute>}> </Route>

                {/* Settings */}
                {/* <Route path="/settings" element={<Settings />}/> */}
                <Route exact path="/settings" element={ <PrivateRoute> <Settings /> </PrivateRoute>}> </Route>

                {/* Trial page */}
                {/* <Route path="/trial" element={<Trial />} /> */}
                <Route exact path="/trial" element={ <PrivateRoute> <Trial /> </PrivateRoute>}> </Route>
                <Route exact path="/storageTrial" element={ <PrivateRoute> <FirebaseStorageTrial /> </PrivateRoute>}> </Route>


                {/* Sign in and sign up */}
                <Route path='/login' element={<Login />} />
                <Route path='/signup' element={<SignUp />} />
                <Route path='/forgotPassword' element={<ForgotPassword />} />
                <Route exact path="/verification" element={ <PrivateRoute> <EmailVerification /> </PrivateRoute>}> </Route>

                {/* Image Editor */}
                <Route exact path="/editor" element={ <PrivateRoute> <Editor /> </PrivateRoute>}> </Route>

                {/* Single Image from storage */}
                <Route exact path="/:id" element={ <PrivateRoute> <SingleImage /> </PrivateRoute>}> </Route>

                {/* Terms of Services and privacy policy */}
                <Route exact path="/privacyPolicy" element={<Privacy />}> </Route>

              </Routes>
            </div>
            </div>

            
        </div>
      </BrowserRouter>
    </div>
  )
}

export default App