import React, { createContext, useContext, useEffect, useState } from 'react'
import { auth, googleprovider, db } from '../firebase'
import { getAdditionalUserInfo } from 'firebase/auth'
import { setDoc, doc, getDoc } from 'firebase/firestore'

const StateContext = createContext()

const initialState = {
    chat: false,
    cart: false,
    userProfile: false,
    notification: false
}

export const ContextProvider = ({ children }) => {


    const [activeMenu, setActiveMenu] = useState(true);
    const [isClicked, setIsClicked] = useState(initialState);
    const [screenSize, setScreenSize] = useState(undefined)

    const [productName, setProductName] = useState(null);
    const [apiEndPoint, setApiEndpoint] = useState(null);

    // Authentication States
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const [isVerified, setIsVerified] = useState(false)


    // Credits and newletter
    const [credits, setCredits] = useState(0)
    const [newsletter, setNewsLetter] = useState(false)

    //Product States
    const [sticker, setSticker] = useState()
    const [artPrint, setArtPrint] = useState()
    const [embroidery, setEmbroidery] = useState()
    const [originalArt, setOriginalArt] = useState()
    const [singleImage, setSingleImage] = useState()


    //Firebase Storage values
    const [imageName, setName] = useState([])
    const [imageUrl, setImageUrl] = useState([])
    const [imageDate, setImageDate] = useState([])
    const [imageId, setImageId] = useState([])






    const handleClick = (clicked) => {
        setIsClicked({ ...initialState, [clicked]: true });
    }

    function signup(email, password) {
        return auth.createUserWithEmailAndPassword(email, password)
    }

    function loginwithgoogle() {
        return auth.signInWithPopup(googleprovider)
    }

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function logout() {
        setIsClicked(initialState)
        return auth.signOut()
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            setCurrentUser(user)
            if (user) {
                setIsVerified(user.emailVerified)
                if (user.metadata.creationTime === user.metadata.lastSignInTime) {
                    const docRef = doc(db, "credits", user.uid)
                    console.log(newsletter)
                    setDoc(docRef, { credit: 500, newsletter: newsletter })
                    setCredits(500)
                }
                else {
                    const docRef = doc(db, "credits", user.uid)
                    const data = await getDoc(docRef)
                    setCredits(data.data().credit)
                }
            }
            setLoading(false)
        })

        return unsubscribe
    }, [])




    async function fetchDashboardData() {
        setName([])
        setImageUrl([])
        setImageDate([])
        setImageId([])
        const docRef = doc(db, 'images', currentUser.uid)
        const data = await getDoc(docRef)
        const dataObj = data.data()
        console.log(dataObj)
        Object.keys(dataObj).forEach(function (key, index) {
            // console.log(dataObj[key)
            var obj = dataObj[key]
            setName(oldName => [...oldName, obj.name])
            setImageUrl(oldImageUrl => [...oldImageUrl, obj.imageUrl])
            setImageDate(oldDate => [...oldDate, obj.date['seconds']])
            setImageId(oldId => [...oldId, obj.id])
            console.log(obj.name)
            // console.log(obj.imageUrl)
            // console.log(obj.date['seconds'])
        })
    }



    return (
        <StateContext.Provider
            value={{
                activeMenu,
                setActiveMenu,
                isClicked, setIsClicked, handleClick,
                screenSize, setScreenSize,
                productName, setProductName,
                apiEndPoint, setApiEndpoint,
                isLoggedIn, setIsLoggedIn,
                signup, currentUser,
                login, logout, resetPassword, isVerified,
                loginwithgoogle,
                sticker, setSticker,
                artPrint, setArtPrint,
                embroidery, setEmbroidery,
                singleImage, setSingleImage,
                credits, setCredits,
                newsletter, setNewsLetter,
                imageName, imageUrl, imageDate, imageId, fetchDashboardData,
            }}
        >
            {!loading && children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext)