import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useStateContext } from '../context/ContextProvider'
import logo from '../data/Logo/TEMPLOGO.png'
import { FcGoogle } from 'react-icons/fc'

const Login = () => {

    const { isLoggedIn, setIsLoggedIn, login, loginwithgoogle, currentUser } = useStateContext()
    const emailRef = useRef()
    const passwordRef = useRef()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if(currentUser){
            navigate("/")
        }
        setIsLoggedIn(false)
    }, [])

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError('')
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
            navigate("/")
        }
        catch (err) {
            console.log(err)
            setLoading(false)
            setError('Failed to log in')
        }

    }

    async function handleGoogle(e) {
        e.preventDefault()

        try {
            setError("")
            setLoading(true)
            await loginwithgoogle()
            navigate("/")
        } catch (err) {
            console.log(err)
            setError("Google Login Failed")
        }
        setLoading(false)
    }

    return (
        <div className=''>
            <div className='bg-sidebar-bg flex align-middle'>
                <div className='px-2 py-4'>
                    <Link to="/signup" className="items-center gap-3 ml-3 flex text-xl font-extrabold tracking-tight text-slate-900"><img src={logo} className="max-h-[30px]" /></Link>
                </div>
            </div>
            <div className='flex p-3 w-full min-h-screen justify-center items-center'>
                <div className='w-full h-content max-w-[400px] text-center px-6 py-10 border-1 rounded-lg'>
                    <h3 className='text-white text-4xl font-bold '>Log In</h3>
                    {error ?
                        <div className="my-2 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                            <span className="block sm:inline">{error}</span>
                        </div> : ""}
                    <form onSubmit={handleSubmit} className='pt-10 text-white'>
                        <input type="email" name="email" class="mb-4 bg-block-bg border border-light-gray text-white  text-sm rounded-lg active:border-light-gray focus:border-transparent block w-full p-2.5 " placeholder="Enter email id" ref={emailRef} required />
                        <input type="password" name="password" class="mb-4 bg-block-bg border border-light-gray text-white  text-sm rounded-lg active:border-light-gray focus:border-transparent block w-full p-2.5 " placeholder="Enter password" ref={passwordRef} required />
                        <button className="mb-4 items-center justify-center flex bg-active-blue text-white w-full py-3 rounded-lg hover:bg-gray-700 gap-3">Submit</button>
                        <span className='mb-4 flex items-center justify-center gap-2'><Link to="/forgotPassword"><p className='font-bold text-[#0177FB] underline'>Forgot Password?</p></Link></span>
                        <span className='mb-4 flex items-center justify-center gap-2'>Don't have an account? <Link to="/signup"><p className='font-bold text-[#0177FB] underline'>Sign Up</p></Link></span>
                        <hr className='mb-4' />
                    </form>
                    <button onClick={handleGoogle} className="mb-4 items-center justify-center flex hover:bg-black text-white w-full py-3 rounded-lg bg-gray-700 gap-3"><FcGoogle className='ml-3' /> Login With Google</button>
                    <div class="p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-gray-800 dark:text-blue-400" role="alert">
                        <span class="font-medium">Info alert!</span> By logging in with google, you are agreeing to our <Link to="/privacyPolicy" className='underline'>Terms of Services and Privacy Policy</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login