import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { useStateContext } from './context/ContextProvider'

const PrivateRoute = ({children}) => {

    const {currentUser} = useStateContext()

    return currentUser ? children : <Navigate to="/login" />;
}

export default PrivateRoute