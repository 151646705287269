import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom';
import { SiShopware } from 'react-icons/si';
import { MdOutlineCancel } from 'react-icons/md';
import {IoMdClose} from 'react-icons/io'
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import logo from '../data/Logo/TEMPLOGO.png'
import { links } from '../data/dummy';
import { useStateContext } from '../context/ContextProvider';

const Sidebar = () => {

  const { activeMenu, setActiveMenu , screenSize} = useStateContext()
  const location = useLocation()
  const pathId = location.pathname.slice(1)

  const handleCloseSideBar = () =>{
    if(activeMenu && screenSize<=900)
    setActiveMenu(false)
  }

  const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-3 rounded-lg text-white text-md m-2 bg-active-blue '
  const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-3 rounded-lg text-dark-gray transform transition duration-50 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2'

  return (
    <div className='p-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 bg-sidebar-bg'>
      {activeMenu && (<>
        <div className='flex justify-between items-center'>
          <Link to="/" onClick={() => handleCloseSideBar} className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight text-slate-900"><img src={logo} className="w-3/5"/></Link>
          <TooltipComponent content="Close" position='BottomCenter'>
            <button type='button' onClick={() => setActiveMenu((prevActiveMenu) => !(prevActiveMenu))} className="text-xl rounded-full text-white p-3 mt-4 block lg:hidden transform transition duration-500 hover:scale-110">
              <IoMdClose />
            </button>
          </TooltipComponent>
        </div>
        <div className='mt-10 ml-3'>
          {links.map((item) => (
            <>
              <div key={item.title} className="pb-3">
                <p className='text-dark-gray m-3 mt-4 font-semibold'>{item.title}</p>
                {item.links.map((link) => (
                  <NavLink to={link.link}
                    key={link.name}
                    onClick={handleCloseSideBar}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink}
                  >
                    {link.icon}
                    <span className='capitalize'>
                      {link.name}
                    </span>
                  </NavLink>
                ))}
                {item.title && <hr className='w-3/4 border-0.5 border-[#6B6D7F]'/>}
              </div>
            </>
          ))}
        </div>
      </>)}
    </div>
  )
}

export default Sidebar