import React, { useState, useEffect, useRef } from 'react'
import Spinner from '../components/Spinner'
import { stickerify } from 'stickerify'
import temp1 from '../data/temp_data/download.png'
import Editor from '../components/Editor'
import BuyCreditsModal from '../components/BuyCreditsModal'
import { useStateContext } from '../context/ContextProvider'
import { db, storage } from '../firebase'
import { getDoc, updateDoc, doc, addDoc, setDoc, collection } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'



const Stickers = () => {

  const [image, setImage] = useState()
  const { sticker, setSticker, credits, setCredits, currentUser, fetchDashboardData } = useStateContext()

  const [loading, setLoading] = useState()
  const [disabled, setDisabled] = useState(false)
  const [status, setStatus] = useState("Generating Image")
  const [name, setName] = useState("")
  const [modalOpen, setModalOpen] = useState(false)
  const [modalOpen2, setModalOpen2] = useState(false)
  //credits modal
  const [modalIsOpen, setIsOpen] = useState(false);
  //isUploading
  const [isUploading, setIsUploading] = useState(false)
  //saved popup
  const [isSaved, setIsSaved] = useState(false)

  function handleCloseModal(e) {
    setIsOpen(false);
  }


  React.useEffect(() => {
    const c = document.getElementById("myCanvas");
    const ctx = c.getContext("2d");
  }, []);

  useEffect(() => {
    async function getCredits() {
      const docRef = doc(db, "credits", currentUser.uid)
      const data = await getDoc(docRef)
      setCredits(data.data().credit)
    }
    getCredits()
  }, [])

  const addShadow = (imgtry) => {
    const canvas = document.getElementById("myCanvas");
    const ctx = canvas.getContext('2d')
    console.log(imgtry)
    var img = new Image;
    img.onload = () => {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      var x = (ctx.canvas.width - img.width) / 2,  // final position
        y = (ctx.canvas.height - img.height) / 2;
      ctx.globalCompositeOperation = "source-in";
      ctx.shadowColor = "#19191d";
      ctx.shadowBlur = 30;
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.globalCompositeOperation = "source-over";
      ctx.drawImage(img, x, y);
      var image2 = canvas.toDataURL("image/png");
      setSticker(image2);
    }
    img.src = imgtry
  }


  const handleSubmit = async (event) => {
    try {
      event.preventDefault()

      if (credits <= 0) {
        setIsOpen(true)
        setDisabled(false)
        setLoading(false)
        return
      }

      setImage(null)
      setDisabled(true)
      setLoading(true)
      setStatus("Generating Image...")
      console.log("Started")

      const design = event.target.elements.design.value;
      const name = design + " - Sticker"
      setName(name)
      let style = event.target.elements.style.value;
      console.log(style)
      if (style !== "")
        style = style + " style,"
      const prompt = design + " , " + style;
      console.log(prompt)
      const response = await fetch(`https://backend.mypodbot.com/generate_sticker/?prompt=${prompt}`)
      var data = await response.json()
      const image1 = `data:image/png;base64,${data[1]}`
      console.log(data[0])
      setSticker(image1)
      // const img = new Image()
      // img.crossOrigin = 'anonymous';
      // img.onload = () => {
      //   var image2 = stickerify(img, 8, 'white').toDataURL();
      //   addShadow(image2)
      // };
      // img.src = `data:image/png;base64,${data[1]}`


      // const blob = await response.blob()
      // const file = new File([blob], 'dot.png', { type: 'image/png' })
      // const formData = new FormData();
      // formData.append('image_file', file)
      // const imageUrl = await blobToBase64(file)
      // setImage(imageUrl)
      // setStatus("Creating Sticker...")

      // const response2 = await fetch('https://backend.mypodbot.com/remove_background/', {
      //   method: 'POST',
      //   body: formData
      // });

      // function blobToBase64(blob) {
      //   return new Promise((resolve, _) => {
      //     const reader = new FileReader();
      //     reader.onloadend = () => resolve(reader.result);
      //     reader.readAsDataURL(blob);
      //   });
      // }
      // const resultSticker = await response2.blob()
      // const stickerUrl = await blobToBase64(resultSticker)
      // const img = new Image()
      // img.crossOrigin = 'anonymous';
      // img.onload = () => {
      //   var image2 = stickerify(img, 8, 'white').toDataURL();
      //   addShadow(image2)
      // };
      // img.src=stickerUrl

      const docref = doc(db, "credits", currentUser.uid)
      await updateDoc(docref, { credit: credits - 1 })
      setCredits(credits - 1)

      console.log("Finished!")
    }
    catch (err) {
      alert("Something went wrong, please try again!")
    }
    setLoading(false)
    setDisabled(false)
  }


  const handleSave = async () => {
    setIsUploading(true)
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        console.log(e);
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", sticker, true);
      xhr.send(null);
    });

    var date = new Date()
    var time = date.getTime()
    console.log(date)

    const docRef = doc(db, 'images', currentUser.uid)

    const uploadRef = ref(storage, `/${currentUser.uid}/${time}`)
    await uploadBytes(uploadRef, blob).then(async (snapshot) => {
      const downloadUrl = await getDownloadURL(uploadRef);
      await setDoc(docRef, {
        [time]: {
          name: name,
          imageUrl: downloadUrl,
          date: date,
          id: time
        }
      }, { merge: true })
      console.log("Uploaded a blob or file")
      fetchDashboardData()
      fetchDashboardData()
      setIsSaved(true)
      setTimeout(() => {
        // After 3 seconds set the show value to false
        setIsSaved(false)
      }, 2000)
    })

    setIsUploading(false)


  }

  return (
    <div className=' my-24 px-6 md:my-12 md:pl-12 w-full h-full'>
      <h1 className='text-white text-3xl font-medium'>Stickers</h1>
      <div className='grid grid-cols-1 lg:grid-cols-2 w-full lg:gap-10 gap-6 mt-10'>
        <div>
          <form onSubmit={handleSubmit}>
            <input type="text" name="design" class="mb-4 bg-block-bg border border-light-gray text-white  text-sm rounded-lg active:border-light-gray focus:border-transparent block w-full p-2.5 " placeholder="Enter Design" required />
            <input type="text" name="style" class="mb-4 bg-block-bg border border-light-gray text-white  text-sm rounded-lg active:border-light-gray focus:border-transparent block w-full p-2.5 " placeholder="Enter Style" />
            <button type="submit" disabled={disabled} className='bg-active-blue px-3 py-2 text-white font-medium rounded-lg hover:bg-black transform transition-all duration-50 disabled:bg-gray-800 disabled:cursor-not-allowed'>
              Generate
            </button>
          </form>
        </div>
        <div className='bg-canvas-bg h-[740px] rounded-xl flex flex-col justify-center p-4'>
          {/* {loading ? <Spinner /> : image ? <><img src={image} className="max-h-[450px] max-w-[80%]"/><a href={image} download className='text-decoration-none text-white mt-10 bg-red-800 px-2 py-2 rounded-lg font-medium text-xl hover:bg-black'>Download</a></>: ""}
          {sticker ? <><img src={sticker} className="max-h-[450px] max-w-[80%]"/><a href={sticker} download className='text-decoration-none text-white mt-10 bg-red-800 px-2 py-2 rounded-lg font-medium text-xl hover:bg-black'>Download</a></>: ""} */}

          {loading ? <Spinner status={status} /> :
            sticker ?
              <div className='flex flex-col space-y-4'>
                {/* <div className='flex justify-around'>
                  <h1 className='text-white text-xl font-medium'>Original Image</h1>
                  <div className='flex flex-col items-center'>
                    <img src={image} className="max-h-[250px]" alt='Original generated by AI' />
                    <div className='gap-x-2 flex'>
                      <a href={image} download className='text-decoration-none text-white mt-5 bg-download-green px-2 py-2 rounded-lg font-medium transform transition-all duration-50 text-black hover:bg-black hover:text-white'>Download</a>
                      <button onClick={() => { setModalOpen2(true) }} className='text-decoration-none text-white mt-5 bg-active-blue px-2 py-2 rounded-lg font-medium hover:bg-black'>Edit</button>
                    </div>
                  </div>
                </div> */}
                <div className='justify-around'>
                  {/* <h2 className='text-white text-xl font-medium'>Sticker</h2> */}
                  <div className='flex flex-col items-center' >
                    <div className='rounded-lg relative'>
                      {isUploading ? <div className='h-full w-full absolute bg-black rounded-lg text-white flex flex-col justify-center items-center opacity-60'><Spinner className="opacity-100" status="Uploading.." /></div> : ""}
                      <img src={sticker} className="max-h-[600px] rounded-lg" alt="Sticker" />
                    </div>

                    <div className='gap-x-2 flex'>
                      <a href={sticker} download className='text-decoration-none text-white mt-5 bg-download-green px-2 py-2 rounded-lg font-medium transform transition-all duration-50 text-black hover:bg-black hover:text-white'>Download</a>
                      <button onClick={() => { setModalOpen(true) }} className='text-decoration-none mt-5 px-2 py-2 rounded-lg font-medium bg-[#FFCA28] hover:bg-black hover:text-white'>Edit</button>
                      <button onClick={() => { handleSave() }} className='text-decoration-none mt-5  px-2 py-2 rounded-lg font-medium  disabled:from-bg-gray-800 disabled:to-bg-gray-800 disabled:cursor-not-allowed bg-[#4FC3F7] hover:bg-black hover:text-white '>{isSaved ? "Saved!" : "Save"}</button>
                    </div>
                  </div>
                </div>
              </div>
              : ""
          }
        </div>
      </div>
      <canvas
        id="myCanvas"
        width="900"
        height="900"
        className="hidden"
      >
        Your browser does not support the HTML canvas tag.
      </canvas>


      {modalOpen2 &&
        <div id="defaultModal" tabindex="-1" aria-hidden="true" class="bg-black bg-opacity-50 fixed top-20 left-0 right-0 md:items-center md:flex md:m-auto justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
          <div class="relative w-full h-full max-w-4xl md:h-auto">
            <div class="relative bg-sidebar-bg rounded-lg shadow dark:bg-gray-700">
              <div class="flex items-start justify-between p-4  rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold text-white">
                  PODBot Editor
                </h3>
                <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                  <svg onClick={() => { setModalOpen2(false) }} aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  <span class="sr-only">Close modal</span>
                </button>
              </div>
              <div>
                <Editor image={image} method={setImage} />
              </div>
            </div>
          </div>
        </div>}

      {modalOpen &&
        <div id="defaultModal" tabindex="-1" aria-hidden="true" class="bg-black bg-opacity-50 fixed top-20 left-0 right-0 md:items-center md:flex md:m-auto justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
          <div class="relative w-full h-full max-w-4xl md:h-auto">
            <div class="relative bg-sidebar-bg rounded-lg shadow dark:bg-gray-700">
              <div class="flex items-start justify-between p-4  rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold bg-siderbar-bg text-white">
                  PODBot Editor
                </h3>
                <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                  <svg onClick={() => { setModalOpen(false) }} aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  <span class="sr-only">Close modal</span>
                </button>
              </div>
              <div>
                <Editor image={sticker} method={setSticker} />
              </div>
            </div>
          </div>
        </div>}

      <BuyCreditsModal
        IsModalOpened={modalIsOpen}
        onCloseModal={handleCloseModal} />

    </div>
  )
}

export default Stickers