import React, { useState, useEffect } from 'react'
import Spinner from '../components/Spinner'
import mergeImages from 'merge-images';
import frame1 from '../data/frames/OneXOne.png'
import frame2 from '../data/frames/FourXThree.png'
import frame3 from '../data/frames/ThreeXFour.png'
import BuyCreditsModal from '../components/BuyCreditsModal'
import { useStateContext } from '../context/ContextProvider'
import Editor from '../components/Editor';
import { db, storage } from '../firebase'
import { getDoc, updateDoc, doc, addDoc, setDoc, collection } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'

const ArtPrint = () => {


  const [image, setImage] = useState()
  const { artPrint, setArtPrint, originalArt, setOriginalArt, credits, setCredits, currentUser, fetchDashboardData } = useStateContext()
  const [loading, setLoading] = useState()
  const [disabled, setDisabled] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  //credits modal
  const [modalIsOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("")
  //upscaling
  const [upscaleLoading, setUpscaleLoading] = useState(false);
  //time and date
  const [date, setDate] = useState()
  const [time, setTime] = useState()
  //custom ratio
  const [isCustom, setIsCustom] = useState(false)
  //uploading Spinner
  const[isUploading, setIsUploading] = useState(false)
  //saved popup
  const[isSaved, setIsSaved] = useState(false)

  function handleCloseModal(e) {
    setIsOpen(false);
  }

  useEffect(() => {
    async function getCredits() {
      const docRef = doc(db, "credits", currentUser.uid)
      const data = await getDoc(docRef)
      setCredits(data.data().credit)
    }
    getCredits()
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()

    try{
      if (credits <= 0) {
        setIsOpen(true)
        setDisabled(false)
        setLoading(false)
        return
      }
  
      setImage(null)
      setArtPrint(null)
      setDisabled(true)
      setLoading(true)
      console.log("Started")
  
      const design = event.target.elements.design.value;
      const name = design + " - Art Print"
      setName(name)
      const style = event.target.elements.style.value;
      const ratio = event.target.elements.ratio.value;
      let height = 768;
      let width = 768;
      let frame = frame1;
      let x = 226;
      let y = 226;
      if (isCustom) {
        const firstRatio = event.target.elements.firstRatio.value;
        const secondRatio = event.target.elements.secondRatio.value;
        if (firstRatio >= secondRatio) {
          width = 1024
          height = Math.round((firstRatio * 128) / secondRatio)*8
          console.log(height + ":" + width)
  
        }
        else {
          height = 1024
          width = Math.round((secondRatio * 128) / firstRatio)*8
          console.log(height + ":" + width)
        }
        console.log(firstRatio + " : " + secondRatio)
      }
      else if (ratio === "768:1024") {
        height = 768;
        width = 1024;
        frame = frame2
        x = 250;
        y = 370
      }
      else if (ratio === "1024:768") {
        height = 1024;
        width = 768;
        frame = frame3
        x = 370;
        y = 250
      }
      console.log(ratio)
      const prompt = design + " , " + style;
      console.log(`https://backend.mypodbot.com/generate_image/?prompt=${prompt}&height=${height}&width=${width}`)
      console.log(prompt)
      const response = await fetch(`https://backend.mypodbot.com/generate_image/?prompt=${prompt}&height=${height}&width=${width}`)
      function blobToBase64(blob) {
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      }
  
      const resultImage = await response.blob()
      const imageUrl = await blobToBase64(resultImage)
      setArtPrint(imageUrl)
  
      // if (isCustom) {
      //   setArtPrint(imageUrl)
      // }
      // else {
  
      //   mergeImages([
      //     { src: imageUrl, x: x, y: y },
      //     { src: frame },
      //   ])
      //     .then(b64 => setArtPrint(b64));
      // }

      const docref = doc(db, "credits", currentUser.uid)
      await updateDoc(docref, { credit: credits - 1 })
      setCredits(credits - 1)
  
      var date2 = new Date()
      var time2 = date2.getTime()
      setDate(date2)
      setTime(time2)
  
      console.log("Finished!")
    }
    catch(err){
      if(isCustom)
      alert("Something went wrong, please try again with a different ratio!")
      else
      alert("Something went wrong")
      console.log(err)
    }

    setLoading(false)
    setDisabled(false)
  }

  const handleSave = async () => {
    setIsUploading(true)
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        console.log(e);
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", artPrint, true);
      xhr.send(null);
    });

    console.log(date)

    const docRef = doc(db, 'images', currentUser.uid)

    const uploadRef = ref(storage, `/${currentUser.uid}/${time}`)
    await uploadBytes(uploadRef, blob).then(async (snapshot) => {
      const downloadUrl = await getDownloadURL(uploadRef);
      await setDoc(docRef, {
        [time]: {
          name: name,
          imageUrl: downloadUrl,
          date: date,
          id: time
        }
      }, { merge: true })
      .then(() =>{
        console.log("Uploaded a blob or file")
      })
      
      fetchDashboardData()
      setIsSaved(true)
      setTimeout(() => {
        // After 3 seconds set the show value to false
        setIsSaved(false)
      }, 2000)
    })

    setIsUploading(false)
  }

  async function handleUpscale() {
    setUpscaleLoading(true)

    try{
      function blobToBase64(blob) {
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      }
  
      function dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);
  
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  
        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
  
        // create a view into the buffer
        var ia = new Uint8Array(ab);
  
        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
  
        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], { type: mimeString });
        return blob;
  
      }
  
      let blob = dataURItoBlob(artPrint)
      const file = new File([blob], 'dot.png', { type: 'image/png' })
      const formData = new FormData();
      formData.append('image_file', file)
  
      const response2 = await fetch('https://backend.mypodbot.com/upscale_image/', {
        method: 'POST',
        body: formData
      });
  
      const resultImage = await response2.blob()
      const artUrl = await blobToBase64(resultImage)
      setArtPrint(artUrl)
    }
    catch(err){
      alert("Something went wrong! Please try again!")
      console.log(err)
    }
    setUpscaleLoading(false)

  }


  const initialDesign = "h-[540px] md:h-[740px] lg:h-[800px]"

  return (
    <div className=' my-24 px-6 md:my-12 md:pl-12 w-full h-full'>
      <h1 className='text-white text-3xl font-medium'>Art Prints</h1>
      <div className='grid grid-cols-1 lg:grid-cols-2 w-full lg:gap-10 gap-6 mt-10'>
        <div>
          <form onSubmit={handleSubmit}>
            <input type="text" name="design" class="mb-4 bg-block-bg border border-light-gray text-white  text-sm rounded-lg active:border-light-gray focus:border-transparent block w-full p-2.5 " placeholder="Enter Design" required />
            <input type="text" name="style" class="mb-4 bg-block-bg border border-light-gray text-white  text-sm rounded-lg active:border-light-gray focus:border-transparent block w-full p-2.5 " placeholder="Enter Style" />

            <ul className="flex space-x-4 mb-4">
              <li onClick={() => { setIsCustom(false) }}>
                <input type="radio" id="1:1" name="ratio" value="1:1" className="hidden peer" />
                <label for="1:1" className="inline-flex justify-between items-center p-2 w-full text-white bg-transparent rounded-lg border cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100/[.06]">
                  <div className="block">
                    <div className="w-full text-lg font-semibold">1:1</div>
                  </div>
                </label>
              </li>
              <li onClick={() => { setIsCustom(false) }}>
                <input type="radio" id="3:4" name="ratio" value="1024:768" className="hidden peer" />
                <label for="3:4" className="inline-flex justify-between items-center p-2 w-full text-white bg-transparent rounded-lg border cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100/[.06]">
                  <div className="block">
                    <div className="w-full text-lg font-semibold">3:4</div>
                  </div>
                </label>
              </li>
              <li onClick={() => { setIsCustom(false) }}>
                <input type="radio" id="4:3" name="ratio" value="768:1024" className="hidden peer" />
                <label for="4:3" className="inline-flex justify-between items-center p-2 w-full text-white bg-transparent rounded-lg border cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100/[.06]">
                  <div className="block">
                    <div className="w-full text-lg font-semibold">4:3</div>
                  </div>
                </label>
              </li>
              <li onClick={() => { setIsCustom(true) }}>
                <input type="radio" id="custom" name="ratio" value="custom" className="hidden peer" />
                <label for="custom" className="inline-flex justify-between items-center p-2 w-full text-white bg-transparent rounded-lg border cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100/[.06]">
                  <div className="block">
                    <div className="w-full text-lg font-semibold">Custom</div>
                  </div>
                </label>
              </li>
            </ul>

            <div className={isCustom ? "mb-4" : "mb-4 hidden"}>
              <label for="4:3" className="inline-flex justify-between items-center p-2 text-white bg-transparent rounded-lg border cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 hover:bg-gray-100/[.06]">
                <div className="block">
                  <div className="text-lg font-semibold">
                    <input name="firstRatio" className='bg-gray-800 w-[30px]' type="number"></input>
                    :
                    <input name="secondRatio" type="number" className='bg-gray-800 w-[30px]'></input></div>
                </div>
              </label>
            </div>

            <button type="submit" disabled={disabled} className='bg-active-blue px-3 py-2 text-white font-medium rounded-lg hover:bg-black transform transition-all duration-50 disabled:bg-gray-800 disabled:cursor-not-allowed'>
              Generate
            </button>
          </form>
        </div>
        <div className={`bg-canvas-bg rounded-xl flex flex-col justify-center items-center p-6 ${image ? "" : initialDesign}`}>
          {loading ?
            <Spinner /> :
            artPrint ?
              <>
                
                <div className='rounded-lg relative'>
                  {isUploading ? <div className='h-full w-full absolute bg-black rounded-lg text-white flex flex-col justify-center items-center opacity-60'><Spinner className="opacity-100" status="Uploading.."/></div>: ""}
                  <img src={artPrint} className="rounded-lg max-h-[400px] md:max-h-[600px] lg:max-h-[700px]" alt="Art Print from the prompt" />
                </div>
                <div className='gap-x-2 flex'>
                  <a href={artPrint} download className='text-decoration-none text-white mt-5 bg-download-green px-2 py-2 rounded-lg font-medium transform transition-all duration-50 text-black hover:bg-black hover:text-white'>Download</a>
                  <button onClick={() => { setModalOpen(true) }} className='text-decoration-none text-black mt-5 bg-[#FFCA28] px-2 py-2 rounded-lg font-medium hover:bg-black hover:text-white'>Edit</button>
                  <button disabled={isUploading} onClick={() => { handleSave() }} className='text-decoration-none text-white mt-5 bg-[#4FC3F7] px-2 py-2 rounded-lg font-medium hover:bg-black disabled:cursor-not-allowed text-black hover:text-white'>{isSaved ? "Saved!" : "Save"}</button>
                  <button onClick={() => { handleUpscale() }} className='text-decoration-none text-white mt-5 bg-[#AB47BC] hover:bg-black px-2 py-2 rounded-lg font-medium'>Upscale</button>
                </div>

                {/* {isSaved ? <p>Saved!!!</p>  : ""} */}
              </>
              : ""}
        </div>
      </div>

      {modalOpen &&
        <div id="defaultModal" tabindex="-1" aria-hidden="true" class="bg-black bg-opacity-50 fixed top-20 left-0 right-0 md:items-center md:flex md:m-auto justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
          <div class="relative w-full h-full max-w-4xl md:h-auto">
            <div class="relative bg-sidebar-bg rounded-lg shadow dark:bg-gray-700">
              <div class="flex items-start justify-between p-4 rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold text-white">
                  PODBot Editor
                </h3>
                <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                  <svg onClick={() => { setModalOpen(false) }} aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  <span class="sr-only">Close modal</span>
                </button>
              </div>
              <div>
                <Editor image={artPrint} method={setArtPrint} />
              </div>
            </div>
          </div>
        </div>}

      {upscaleLoading &&
        <div id="defaultModal" tabindex="-1" aria-hidden="true" class="bg-black bg-opacity-50 fixed top-20 left-0 right-0 md:items-center md:flex md:m-auto justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
          <div class="relative w-full h-full max-w-4xl md:h-auto justify-between">
            <div class="relative rounded-lg shadow ">
              <div class="flex items-start justify-center p-4 rounded-t ">
                <Spinner status={"Please wait while we upscale your image. It may take some time...."} />
              </div>
            </div>
          </div>
        </div>}

      <BuyCreditsModal
        IsModalOpened={modalIsOpen}
        onCloseModal={handleCloseModal} />
    </div>
  )
}

export default ArtPrint