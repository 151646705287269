import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { dashboardCardsData, dashboardDesignsData, Bardata, dummydata } from '../data/dummy'
import { ResponsiveBar } from '@nivo/bar'
import { addDoc, doc, serverTimestamp, setDoc, updateDoc, collection, getDoc, deleteField } from 'firebase/firestore'
import { db, storage } from '../firebase'
import { ref, listAll, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage'
import { useStateContext } from '../context/ContextProvider'
import Spinner from '../components/Spinner'
import { update } from '@syncfusion/ej2/inplace-editor';


const Dashboard = () => {

  const [inputText, setInputText] = useState("")
  // const[imageName, setName] = useState([])
  // const[imageUrl, setImageUrl] = useState([])
  // const[imageDate, setImageDate] = useState([])
  const [loading, setLoading] = useState(false)
  const { currentUser, imageName, imageUrl, imageDate, imageId, fetchDashboardData, isVerified } = useStateContext()

  function convertTimestamp(timestamp) {
    var d = new Date(timestamp * 1000), // Convert the passed timestamp to milliseconds
      yyyy = d.getFullYear(),
      mm = ('0' + (d.getMonth() + 1)).slice(-2),  // Months are zero based. Add leading 0.
      dd = ('0' + d.getDate()).slice(-2),         // Add leading 0.
      hh = d.getHours(),
      h = hh,
      min = ('0' + d.getMinutes()).slice(-2),     // Add leading 0.
      ampm = 'AM',
      time;

    if (hh > 12) {
      h = hh - 12;
      ampm = 'PM';
    } else if (hh === 12) {
      h = 12;
      ampm = 'PM';
    } else if (hh == 0) {
      h = 12;
    }

    // ie: 2014-03-24, 3:00 PM
    time = yyyy + '-' + mm + '-' + dd + ', ' + h + ':' + min + ' ' + ampm;
    return time;
  }

  useEffect(async () => {
    if (imageName.length === 0) {
      fetchDashboardData()
    }
    console.log("Verified: "+ isVerified)
  }, [])


  let inputHandler = (e) => {
    console.log(e.target.value)
    var lowercase = e.target.value.toLowerCase()
    setInputText(lowercase)
  }

  const filteredData = dashboardCardsData.filter((el) => {
    if (inputText === '') {
      return el;
    }

    else {
      return el.title.toLowerCase().includes(inputText)
    }
  })

  async function handleDelete(id) {
    const docRef = doc(db, 'images', currentUser.uid)
    const storageRef = ref(storage, `/${currentUser.uid}/${id}`)
    console.log(id)
    await deleteObject(storageRef).then(() => {
      console.log("File Deleted Successfully")
    })
    await updateDoc(docRef, {
      [id]: deleteField()
    })
    console.log("Deleted")
    fetchDashboardData()
  }



  return (
    <div className=' my-24 px-6 md:my-12 md:pl-12 w-full h-full'>
      <div className=''>
        <h1 className='text-white text-3xl font-medium'>Product Type</h1>
        <div className='grid grid-cols-1 lg:grid-cols-2 w-full lg:gap-10 gap-6 mt-10'>
          <div>
            <input type="text" class="bg-[#343646] border border-[#343646] text-white  text-sm rounded-lg active:border-[#343646] focus:border-transparent block w-full p-2.5 " placeholder="Search or type" onChange={inputHandler} />
            <div className='grid md:grid-cols-2 gap-10 mt-10'>
              {filteredData.map((item) => (
                <Link to={`/${item.link}`} key={item.id} >
                  <div className='relative w-full bg-[#2A2C3E] p-4 h-48 rounded-xl cursor-pointer '>
                    <div className={`relative bg-no-repeat bg-cover bg-center rounded-xl h-full rounded-lg transform transition duration-100 hover:scale-95`} style={{ backgroundImage: `url(${item.image})` }}>
                      <p className='absolute bottom-4 left-4'>
                        <span className='bg-white px-2 py-1 rounded-xl font-medium'>{item.title}</span>
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>

          <div>
            <div className='h-[750px] w-full bg-sidebar-bg rounded-xl p-6'>
              <p className='text-2xl font-medium text-white pb-2'>My Designs</p>
              <div className='py-6 h-[600px] overflow-y-auto'>
                {imageName.length ? imageName.map((val, index) => (

                  <div key={index} className='h-[100px] py-4 mx-auto bg-[#37394D] rounded-xl shadow-lg flex items-center mb-6 transform transition duration-100 hover:bg-black cursor-pointer'>
                    <div className='flex w-full justify-between'>
                      <div className='flex relative px-4 space-x-4'>
                        <div className="h-[75px] w-[75px] relative bg-no-repeat bg-cover bg-center rounded-xl h-full rounded-lg" style={{ backgroundImage: `url(${imageUrl[index]})` }}></div>
                        {/* <img src={item.image} className='h-[75px] rounded-lg' /> */}
                        <div className='flex flex-col'>
                          <h1 className='text-white font-medium text-lg mb-2'>{val}</h1>
                          <p className='text-sm text-[#6B6D7F] font-medium'>{convertTimestamp(imageDate[index])}</p>
                        </div>
                      </div>
                      <div className='flex justify-center items-center pr-10'>
                        <Link to={`/${imageId[index]}`} className='bg-green-800  text-white p-2 rounded-lg hover:bg-gray-800 mr-2'><button >Edit</button></Link>
                        {/* <button onClick={() => handleDelete(imageId[index])} className='bg-red-800  text-white p-2 rounded-lg hover:bg-gray-800'>Delete</button> */}
                      </div>
                    </div>
                  </div>
                )) :
                  <div className='h-full flex justify-center items-center'>
                    <p className='text-dark-gray font-medium text-xl'>You don't have any saved designs yet!</p>
                  </div>}
              </div>
              <Link to="/mydesigns" className='flex justify-center '>
                <p className='text-white my-5 text-[#0177FB] font-bold text-xl'>View All</p>
              </Link>
            </div>

            {/* <div className='h-[250px] w-full bg-sidebar-bg rounded-xl p-6 mt-12'>
              <p className='text-2xl font-medium text-white pb-2'>Generations</p>
              <div className='h-full'>
                <MyResponsiveBar />
              </div>
            </div> */}
          </div>
        </div>
      </div>


    </div>
  )
}

export default Dashboard