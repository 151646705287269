import React,{useState} from 'react'
import { useStateContext } from '../context/ContextProvider'
import {useNavigate} from 'react-router-dom'


const UserProfile = () => {

  const[error,setError] = useState()
  const {currentUser, logout} = useStateContext()
  const navigate = useNavigate()

  async function handleLogout(){
    setError('')

    try{
      await logout()
      navigate('/login')
    }
    catch(err){
      console.log(err)
      setError('Failed to log out!')
    }
  }

  return (
    <div class="absolute right-0 z-10 mt-[15px] w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
    <div class="py-1" role="none">
      {/* <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" --> */}
      {/* <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">Account settings</a>
      <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">Support</a>
      <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">License</a> */}
      <button onClick={handleLogout} class="text-gray-700 block w-full px-4 py-2 text-left text-sm" role="menuitem" tabindex="-1" id="menu-item-3">Sign out</button>
    </div>
  </div>
  )
}

export default UserProfile