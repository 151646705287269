import React, { useState } from 'react'
import Spinner from '../components/Spinner'

const Seo = () => {

    const [tags, setTags] = useState(null)
    const [isTagsLoading, setIsTagsLoading] = useState(false)
    const [copyTags, setCopyTags] = useState("Copy Tags")
    const [title, setTitle] = useState(null)
    const [isTitleLoading, setIsTitleLoading] = useState(false)
    const [copyTitle, setCopyTitle] = useState("Copy Title")
    const [description, setDescription] = useState(null)
    const [isDescriptionLoading, setIsDescriptionLoading] = useState(false)
    const [copyDesc, setCopyDesc] = useState("Copy Descr")

    const handleTagsSubmit = async (event) => {
        event.preventDefault()
        try {
            setIsTagsLoading(true)
            setCopyTags("Copy Tags")
            setTags(null)
            console.log("Started")
            const input = event.target.elements.tags.value;
            const prompt = input;
            console.log(prompt)
            const response = await fetch(`https://backend.mypodbot.com/generate_text/tags?design=${prompt}`, {
                method: 'POST'
            })
            const tagResponse = await response.text()
            setTags(tagResponse.substring(1, tagResponse.length - 1))
        }
        catch (error) {
            console.log(error)
            alert("Something went wrong, please try again")
        }
        setIsTagsLoading(false)

    }

    const handleTitleSubmit = async (event) => {
        event.preventDefault()
        try {
            setIsTitleLoading(true)
            setCopyTitle("Copy Title")
            setTitle(null)
            console.log("Started")
            const input = event.target.elements.title.value;
            const product = event.target.elements.product.value;
            console.log(prompt)
            const response = await fetch(`https://backend.mypodbot.com/generate_text/title?design=${input}&product=${product}`, {
                method: 'POST'
            })
            const tagResponse = await response.text()
            console.log(tagResponse)
            setTitle(tagResponse.substring(2, tagResponse.length - 3) + `"`)
        }
        catch (err) {
            console.log(err)
            alert("Something went wrong, please try again!")
        }
        setIsTitleLoading(false)

    }

    const handleDescriptionSubmit = async (event) => {
        event.preventDefault()
        try{
            setIsDescriptionLoading(true)
        setDescription(null)
        console.log("Started")
        const input = event.target.elements.description.value;
        const product = event.target.elements.product.value;
        console.log(prompt)
        const response = await fetch(`https://backend.mypodbot.com/generate_text/description?design=${input}&product_type=${product}`, {
            method: 'POST'
        })
        const tagResponse = await response.text()
        setDescription(tagResponse.substring(1, tagResponse.length - 1))
        }
        catch(err){
            console.log(err)
            alert("Something went wrong, please try again!")
        }
        setIsDescriptionLoading(false)
    }


    async function copyText(val, type) {
        await window.navigator.clipboard.writeText(val)
        if (type === "tags")
            setCopyTags("Copied!")
        else if (type === "title")
            setCopyTitle("Copied!!")
        else if (type === "description")
            setCopyDesc("Copied!!")

        console.log("copied!")
    }


    return (
        <div className=' my-24 px-6 md:my-12 md:pl-12 w-full h-full'>
            <div className=''>
                <h1 className='text-white text-3xl font-medium'>Tags</h1>
                <div className=' mt-6'>
                    <form className='flex flex-col md:flex-row lg:flex-row lg:max-w-[50%] gap-3 lg:gap-6' onSubmit={handleTagsSubmit}>
                        <input type="text" name="tags" class="mb-4 bg-block-bg border border-light-gray text-white  text-sm rounded-lg active:border-light-gray focus:border-transparent block w-full p-2.5 " placeholder="Base Tag" required />
                        <div>
                            <button type="submit" className='mb-5 bg-active-blue px-3 py-2 text-white font-medium rounded-lg hover:bg-black transform transition-all duration-50 disabled:bg-gray-800 disabled:cursor-not-allowed'>
                                Generate
                            </button>
                        </div>
                    </form>
                </div>
                <div className='bg-canvas-bg h-[150px] lg:max-w-[60%] rounded-lg relative  overflow-y-auto p-4'>
                    {isTagsLoading ?
                        <Spinner /> :
                        tags ?
                            <div className='w-full h-full overflow-y-auto'>
                                <div className='absolute m-4 right-0 bottom-0 rounded-lg bg-active-blue p-2 opacity-50 hover:opacity-100 cursor-pointer' onClick={() => copyText(tags, "tags")} ><p className='text-white'>{copyTags}</p> </div>
                                <p className='text-white text-lg pb-2'>
                                    {tags}
                                </p>
                            </div>
                            : ""}
                </div>
            </div>

            <div className='mt-20'>
                <h1 className='text-white text-3xl font-medium'>Title</h1>
                <div className=' mt-6'>
                    <form className='flex flex-col md:flex-row lg:flex-row lg:max-w-[50%] gap-2 lg:gap-6' onSubmit={handleTitleSubmit}>
                        <input type="text" name="title" class="mb-4 bg-block-bg border border-light-gray text-white  text-sm rounded-lg active:border-light-gray focus:border-transparent block w-full p-2.5 " placeholder="Product Type" required />
                        <input type="text" name="product" class="mb-4 bg-block-bg border border-light-gray text-white  text-sm rounded-lg active:border-light-gray focus:border-transparent block w-full p-2.5 " placeholder="Design" required />
                        <div>
                            <button type="submit" className='mb-5 bg-active-blue px-3 py-2 text-white font-medium rounded-lg hover:bg-black transform transition-all duration-50 disabled:bg-gray-800 disabled:cursor-not-allowed'>
                                Generate
                            </button>
                        </div>
                    </form>
                </div>
                <div className='bg-canvas-bg h-[150px] lg:max-w-[60%] rounded-lg relative  overflow-y-auto p-4'>
                    {isTitleLoading ?
                        <Spinner /> :
                        title ?
                            <div className='w-full h-full overflow-y-auto'>
                                <div className='absolute m-4 right-0 bottom-0 rounded-lg bg-active-blue p-2 opacity-50 hover:opacity-100 cursor-pointer' onClick={() => copyText(title, "title")} ><p className='text-white'>{copyTitle}</p> </div>
                                <p className='text-white text-lg'>
                                    {title}
                                </p>
                            </div>
                            : ""}
                </div>
            </div>

            <div className='mt-20'>
                <h1 className='text-white text-3xl font-medium'>Description</h1>
                <div className=' mt-6'>
                    <form className='flex flex-col md:flex-row lg:flex-row lg:max-w-[50%] gap-2 lg:gap-6' onSubmit={handleDescriptionSubmit}>
                        <input type="text" name="description" class="mb-4 bg-block-bg border border-light-gray text-white  text-sm rounded-lg active:border-light-gray focus:border-transparent block w-full p-2.5 " placeholder="Product Type" required />
                        <input type="text" name="product" class="mb-4 bg-block-bg border border-light-gray text-white  text-sm rounded-lg active:border-light-gray focus:border-transparent block w-full p-2.5 " placeholder="Design" required />
                        <div>
                            <button type="submit" className='mb-5 bg-active-blue px-3 py-2 text-white font-medium rounded-lg hover:bg-black transform transition-all duration-50 disabled:bg-gray-800 disabled:cursor-not-allowed'>
                                Generate
                            </button>
                        </div>
                    </form>
                </div>
                <div className='bg-canvas-bg h-[150px] lg:max-w-[60%] rounded-lg  overflow-y-auto p-4 relative'>
                    {isDescriptionLoading ?
                        <Spinner /> :
                        description ?
                            <div className='w-full h-full  overflow-y-auto'>
                                <div className='absolute m-4 right-0 bottom-0 rounded-lg bg-active-blue p-2 opacity-50 hover:opacity-100 cursor-pointer' onClick={() => copyText(description, "description")} ><p className='text-white'>{copyDesc}</p> </div>
                                <p className='text-white text-lg pb-4'>
                                    {description}
                                </p>
                            </div>
                            : ""}
                </div>
            </div>

        </div>
    )
}

export default Seo