import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useStateContext } from '../context/ContextProvider'
import logo from '../data/Logo/TEMPLOGO.png'
import { FcGoogle } from 'react-icons/fc'
import { load } from '@syncfusion/ej2-react-charts'

const SignUp = () => {

    const { isLoggedIn, setIsLoggedIn, signup, loginwithgoogle, newsletter, setNewsLetter, currentUser } = useStateContext()
    const emailRef = useRef()
    const passwordRef = useRef()
    const confirmPasswordRef = useRef()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [checked, setChecked] = useState(false)
    const[privacyError, setPrivacyError] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if(currentUser){
            navigate("/")
        }
        setIsLoggedIn(false)
    }, [])

    async function handleSubmit(e) {
        e.preventDefault()

        console.log(newsletter)
        if (passwordRef.current.value !== confirmPasswordRef.current.value) {
            return setError('Passwords do not match')
        }
        if (passwordRef.current.value.length < 6) {
            return setError('Password must be at least 6 characters long')
        }

        try {
            setError('')
            setLoading(true)
            await signup(emailRef.current.value, passwordRef.current.value)
            navigate("/")
        }
        catch (err) {
            console.log(err)
            setLoading(false)
            setError('Failed to create an account')
        }

    }

    async function handleGoogle(e) {
        e.preventDefault()

        if(!checked){
            setPrivacyError(true)
            return
        }

        try {
            setPrivacyError(false)
            setError("")
            setLoading(true)
            await loginwithgoogle()
            navigate("/")
        } catch (err) {
            console.log(err)
            setError("Google Login Failed")
        }
        setLoading(false)
    }

    return (
        <div className=''>
            <div className='bg-sidebar-bg flex align-middle'>
                <div className='px-2 py-4'>
                    <Link to="/signup" className="items-center gap-3 ml-3 flex text-xl font-extrabold tracking-tight text-slate-900"><img src={logo} className="max-h-[30px]" /></Link>
                </div>
            </div>
            <div className='flex p-3 w-full min-h-screen justify-center items-center'>
                <div className='w-full h-content max-w-[400px] text-center px-6 py-10 border-1 rounded-lg'>
                    <h3 className='text-white text-4xl font-bold '>Sign Up</h3>
                    {error ?
                        <div className="my-2 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                            <span className="block sm:inline">{error}</span>
                        </div> : ""}
                    <form className='pt-10 text-white' onSubmit={handleSubmit}>
                        <input type="email" name="email" class="mb-4 bg-block-bg border border-light-gray text-white  text-sm rounded-lg active:border-light-gray focus:border-transparent block w-full p-2.5 " placeholder="Enter email id" ref={emailRef} required />
                        <input type="password" name="password" class="mb-4 bg-block-bg border border-light-gray text-white  text-sm rounded-lg active:border-light-gray focus:border-transparent block w-full p-2.5 " placeholder="Enter password" ref={passwordRef} required />
                        <input type="password" name="confirmPassword" class="mb-4 bg-block-bg border border-light-gray text-white  text-sm rounded-lg active:border-light-gray focus:border-transparent block w-full p-2.5 " placeholder="Confirm Password" ref={confirmPasswordRef} required />
                        <div className='mb-4 text-white text-left'>
                            <label>
                                <input
                                    type="checkbox"
                                    required="required"
                                    checked={checked}
                                    onChange={() => { setChecked(!checked) }}
                                />
                                &nbsp;&nbsp; I agree to PodBOT's <Link to="/privacyPolicy" className='text-[#0177FB]'>Terms of Service and Privacy Policy</Link>
                            </label>
                        </div>
                        <div className='mb-4 text-white text-left'>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={newsletter}
                                    onChange={() => {setNewsLetter(!newsletter)}}
                                />
                                &nbsp;&nbsp; Subscribe to our newsletter
                            </label>
                        </div>
                        <button disabled={loading} className="mb-4 items-center justify-center flex bg-active-blue text-white w-full py-3 rounded-lg hover:bg-gray-700 gap-3">Submit</button>
                        <span className='mb-4 flex items-center justify-center gap-2'>Already have an account? <Link to="/login"><p className='font-bold text-[#0177FB] underline'>Log In</p></Link></span>
                        <hr className='mb-4' />
                    </form>

                    <button onClick={handleGoogle} className="mb-4 items-center justify-center flex hover:bg-black text-white w-full py-3 rounded-lg bg-gray-700 gap-3"><FcGoogle className='ml-3' /> Login With Google</button>
                   {privacyError &&
                    <div class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-gray-800 dark:text-red-400" role="alert">
                    <span class="font-medium"></span> Please accept our Terms of Services and Privacy Policy
                </div>}
                </div>
            </div>
        </div>
    )
}

export default SignUp