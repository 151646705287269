import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useStateContext } from '../context/ContextProvider'
import logo from '../data/Logo/TEMPLOGO.png'
import { FcGoogle } from 'react-icons/fc'

const ForgotPassword = () => {

    const {setIsLoggedIn, currentUser, resetPassword } = useStateContext()
    const emailRef = useRef()
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (currentUser) {
            navigate("/")
        }
        setIsLoggedIn(false)
    }, [])

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError('')
            setLoading(true)
            await resetPassword(emailRef.current.value)
            setSuccess("Please check your email to reset password")
        }
        catch (err) {
            console.log(err)
            setLoading(false)
            setError('Failed to log in')
        }

    }


    return (
        <div className=''>
            <div className='bg-sidebar-bg flex align-middle'>
                <div className='px-2 py-4'>
                    <Link to="/signup" className="items-center gap-3 ml-3 flex text-xl font-extrabold tracking-tight text-slate-900"><img src={logo} className="max-h-[30px]" /></Link>
                </div>
            </div>
            <div className='flex p-3 w-full min-h-screen justify-center items-center'>
                <div className='w-full h-content max-w-[400px] text-center px-6 py-10 border-1 rounded-lg'>
                    <h3 className='text-white text-4xl font-bold '>Reset Password</h3>
                    {error ?
                        <div className="my-2 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                            <span className="block sm:inline">{error}</span>
                        </div> : ""}
                    {success ?
                        <div className="my-2 bg-red-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                            <span className="block sm:inline">{success}</span>
                        </div> : ""}
                    <form onSubmit={handleSubmit} className='pt-10 text-white'>
                        <input type="email" name="email" class="mb-4 bg-block-bg border border-light-gray text-white  text-sm rounded-lg active:border-light-gray focus:border-transparent block w-full p-2.5 " placeholder="Enter email id" ref={emailRef} required />
                        <button className="mb-4 items-center justify-center flex bg-active-blue text-white w-full py-3 rounded-lg hover:bg-gray-700 gap-3">Submit</button>
                        <span className='mb-4 flex items-center justify-center gap-2'><Link to="/login"><p className='font-bold text-[#0177FB] underline'>Log In</p></Link></span>
                        <hr className='mb-4' />
                        <span className='mb-4 flex items-center justify-center gap-2'>Don't have an account? <Link to="/signup"><p className='font-bold text-[#0177FB] underline'>Sign Up</p></Link></span>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword