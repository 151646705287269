// import React, { useState } from 'react'
// import img1 from '../data/temp_data/download.png'
// import { stickerify } from 'stickerify'

// const Trial = () => {

//   const[finalImage, setFinalImage] = useState(null)

//   React.useEffect(() => {
//     const c = document.getElementById("myCanvas");
//     const ctx = c.getContext("2d");
//     // ctx.moveTo(0, 0);
//     // ctx.lineTo(200, 100);
//     // ctx.stroke();
//   }, []);

//   const handleClick = () =>{
//     const img = img1;
//     var img2=null;
//     const image = new Image();
//     image.crossOrigin = 'anonymous';
//     image.onload = () =>{
//       img2 = stickerify(image, 8, 'white').toDataURL();
//       addPoint(img2)
//     }
//     image.src = img1;
//   }

//   const addPoint = (imgtry) => {
//     const canvas = document.getElementById("myCanvas");
//     const ctx = canvas.getContext('2d')
//     console.log(imgtry)
//     var img = new Image;
//     img.onload = () => {
//       // img = stickerify(img, 8, 'white').toDataURL();
//       console.log("Inside")
//       var dArr = [-1, -1, 0, -1, 1, -1, -1, 0, 1, 0, -1, 1, 0, 1, 1, 1], // offset array
//         s = 3,  // thickness scale
//         i = 0,  // iterator
//         x = 5,  // final position
//         y = 5;
//       for (; i < dArr.length; i += 2)
//         ctx.drawImage(img, x + dArr[i] * s, y + dArr[i + 1] * s);
//       ctx.globalCompositeOperation = "source-in";
//       // ctx.fillStyle = "black";
//       ctx.shadowColor = "black";
//       ctx.shadowBlur = 15;
//       ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

//       ctx.globalCompositeOperation = "source-over";
//       ctx.drawImage(img, x, y);
//       // ctx.drawImage(img, 0, 0);
//       var image2 = canvas.toDataURL("image/png");

//       // document.getElementById("finalImage").src = image2;
//       setFinalImage(image2)
//     }
//     img.src = imgtry
//     // var image2 = null

//     // setFinalImage(image2)
//     // console.log(image2)

//   }

//   return (
//     <div>
//       <h1>HTML5 Canvas + React.js</h1>
//       <canvas
//         id="myCanvas"
//         width="800"
//         height="700"
//         style={{ border: "1px solid #d3d3d3" }}
//         className="hidden"
//       >
//         Your browser does not support the HTML canvas tag.
//       </canvas>
//       <button onClick={() => handleClick()}>Add Point</button>

//       {finalImage ? <img src={finalImage}  className="max-h-[540px]"></img> : ""}

//       {/* <img id="finalImage"/> */}
//     </div>
//   );
// }

// export default Trial








// import React, { useState } from 'react'
// import { stickerify } from 'stickerify'

// const Trial = () => {

//   const[sticker, setSticker] = useState(null)
//   const[image, setImage] = useState(null)

//   React.useEffect(() => {
//     const c = document.getElementById("myCanvas");
//     const ctx = c.getContext("2d");
//   }, []);

//   const addShadow = (imgtry) => {
//     const canvas = document.getElementById("myCanvas");
//     const ctx = canvas.getContext('2d')
//     console.log(imgtry)
//     var img = new Image;
//     img.onload = () => {
//       ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
//       ctx.fillStyle = "white";
//       ctx.fillRect(0, 0, canvas.width, canvas.height);
//       var x = (ctx.canvas.width-img.width)/2,  // final position
//       y = (ctx.canvas.height-img.height)/2;
//       ctx.globalCompositeOperation = "source-in";
//       ctx.shadowColor = "#19191d";
//       ctx.shadowBlur = 30;
//       ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
//       ctx.globalCompositeOperation = "source-over";
//       ctx.drawImage(img, x, y);
//       var image2 = canvas.toDataURL("image/png");
//       setSticker(image2);
//     }
//     img.src = imgtry
//   }
  
//   const handleSubmit = async (event) => {
//     event.preventDefault()

//     setSticker(null)

//     const design = event.target.elements.design.value;
//     let style = event.target.elements.style.value;
//     console.log(style)
//     if(style!=="")
//       style = style+" style,"
//     const prompt = design + " , "+ style + " white background";
//     console.log(prompt)
//     const response = await fetch(`http://5.178.113.239:8000/generate_sticker/?prompt=${prompt}`)
//     var data = await response.json()
//     console.log(data[0])
//     setImage(data[0])
//     const img = new Image()
//     img.crossOrigin = 'anonymous';
//     img.onload = () => {
//       var image2 = stickerify(img, 8, 'white').toDataURL();
//       addShadow(image2)
//     };
//     img.src=`data:image/png;base64,${data[1]}`
//   }


//   return (
//     <div>
//       <form onSubmit={handleSubmit}>
//             <input type="text" name="design" class="mb-4 bg-block-bg border border-light-gray text-white  text-sm rounded-lg active:border-light-gray focus:border-transparent block w-full p-2.5 " placeholder="Enter Design" required/>
//             <input type="text" name="style" class="mb-4 bg-block-bg border border-light-gray text-white  text-sm rounded-lg active:border-light-gray focus:border-transparent block w-full p-2.5 " placeholder="Enter Style" />
//             <button type="submit" className='bg-active-blue px-3 py-2 text-white font-medium rounded-lg hover:bg-black transform transition-all duration-50 disabled:bg-gray-800 disabled:cursor-not-allowed'>
//               Generate
//             </button>
//       </form>
//       {image ? <img src={`data:image/png;base64,${image}`}/> : ""}
//       {sticker ? <img src={sticker}/> : ""}

//       <canvas
//         id="myCanvas"
//         width="900"
//         height="900"
//         style={{ border: "1px solid #d3d3d3" }}
//         className="hidden"
//       >
//         Your browser does not support the HTML canvas tag.
//       </canvas>
//     </div>
//   )
// }

// export default Trial








// import React, {useState, useEffect} from 'react'
// import {db} from '../firebase'
// import {getDoc, doc} from 'firebase/firestore'
// import { useStateContext } from '../context/ContextProvider';

// const Trial = () => {

//   const {currentUser} = useStateContext()
//   const[credits, setCredits] = useState([])
//   const usersCollectionRef = doc(db,"credits", currentUser.uid)

//   useEffect(()=>{
    
//     const getUsers = async () =>{
//       const data = await getDoc(usersCollectionRef)
//       console.log(data.data())
//       setCredits(data.data().credit)
//     }

//     getUsers()
//   },[])
  
//   return (
//     <div>
//      <h1>{credits}</h1>
//     </div>
//   )
// }

// export default Trial






import React,{useState, useCallback, useEffect} from 'react'
import BuyCreditsModal from './BuyCreditsModal'
import { useStateContext } from '../context/ContextProvider'

const Trial = () => {

  const [modalIsOpen, setIsOpen] = useState(true);

  function handleCloseModal(e) {
    setIsOpen(false);
  }



  return (
    <div>
      <BuyCreditsModal 
      IsModalOpened={modalIsOpen}
      onCloseModal={handleCloseModal}/>
    </div>
  )
}

export default Trial