import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useStateContext } from '../context/ContextProvider'
import { doc, updateDoc, deleteField } from 'firebase/firestore'
import { db, storage } from '../firebase'
import { ref, deleteObject } from 'firebase/storage'

const MyDesigns = () => {
  const { currentUser, imageName, imageUrl, imageDate, imageId, fetchDashboardData } = useStateContext()

  function convertTimestamp(timestamp) {
    var d = new Date(timestamp * 1000), // Convert the passed timestamp to milliseconds
      yyyy = d.getFullYear(),
      mm = ('0' + (d.getMonth() + 1)).slice(-2),  // Months are zero based. Add leading 0.
      dd = ('0' + d.getDate()).slice(-2),         // Add leading 0.
      hh = d.getHours(),
      h = hh,
      min = ('0' + d.getMinutes()).slice(-2),     // Add leading 0.
      ampm = 'AM',
      time;

    if (hh > 12) {
      h = hh - 12;
      ampm = 'PM';
    } else if (hh === 12) {
      h = 12;
      ampm = 'PM';
    } else if (hh == 0) {
      h = 12;
    }

    // ie: 2014-03-24, 3:00 PM
    time = yyyy + '-' + mm + '-' + dd + ', ' + h + ':' + min + ' ' + ampm;
    return time;
  }

  useEffect(async () => {
    if (imageName.length === 0) {
      fetchDashboardData()
    }
  }, [])

  async function handleDelete(id) {
    const docRef = doc(db, 'images', currentUser.uid)
    const storageRef = ref(storage, `/${currentUser.uid}/${id}`)
    console.log(id)
    await deleteObject(storageRef).then(() => {
      console.log("File Deleted Successfully")
    })
    await updateDoc(docRef, {
      [id]: deleteField()
    })
    console.log("Deleted")
    fetchDashboardData()
  }


  return (
    <div className=' my-24 px-6 md:my-12 md:pl-12 w-full h-full'>
      <div className=''>
        <h1 className='text-white text-3xl font-medium'>My Designs</h1>
        <div className='grid grid-cols-1 lg:grid-cols-2 w-full gap-6 mt-10'>

          {imageName.length ? imageName.map((val, index) => (
            <div key={index} className='w-full h-[100px] py-4 mx-auto bg-[#37394D] rounded-xl shadow-lg flex items-center mb-6 transform transition duration-100 hover:bg-black cursor-pointer'>
              <div className='flex w-full justify-between'>
                <div className='flex relative px-4 space-x-4'>
                  <div className="h-[75px] w-[75px] relative bg-no-repeat bg-cover bg-center rounded-xl h-full rounded-lg" style={{ backgroundImage: `url(${imageUrl[index]})` }}></div>
                  <div className='flex flex-col'>
                    <h1 className='text-white font-medium text-lg mb-2'>{val}</h1>
                    <p className='text-sm text-[#6B6D7F] font-medium'>{convertTimestamp(imageDate[index])}</p>
                  </div>
                </div>
                <div className='flex justify-center items-center pr-10'>
                    <Link to={`/${imageId[index]}`} className='bg-green-800  text-white p-2 rounded-lg hover:bg-gray-800 mr-2'><button >Edit</button></Link>
                    {/* <button onClick={() => handleDelete(imageId[index])} className='bg-red-800  text-white p-2 rounded-lg hover:bg-gray-800'>Delete</button> */}
                  </div>
              </div>
            </div>
          )) :
            <div className='h-full flex justify-center items-center'>
              <p className='text-dark-gray font-medium text-xl'>You don't have any saved designs yet!</p>
            </div>}

        </div>
      </div>


    </div>

  )
}

export default MyDesigns