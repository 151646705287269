import React from 'react'
import '@pqina/pintura/pintura.css';
import { useRef } from 'react';
import { PinturaEditor } from '@pqina/react-pintura';
import { getEditorDefaults } from '@pqina/pintura';
import trial from '../data/temp_data/download.png'
import { useStateContext } from '../context/ContextProvider'


const editorDefaults = getEditorDefaults();

const updateFile = (file, method) => {
    // Create a hidden link and set the URL using createObjectURL

    const preview = new Image();
    preview.src = URL.createObjectURL(file);
    method(preview.src)
};


const Editor = (props) => {

    const { sticker, setSticker, setArtPrint, setEmbroidery } = useStateContext()

    const editorRef = useRef(null);

    const handleEditorProcess = (imageState) => {
        updateFile(imageState.dest, props.method);
    };

    return (
        <div style={{ height: '600px' }}>
            <PinturaEditor
                ref={editorRef}
                {...editorDefaults}
                src={props.image}
                onProcess={handleEditorProcess}
            />
        </div>
    )
}

export default Editor