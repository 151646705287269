import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { FiShoppingCart } from 'react-icons/fi'
import { BsChatLeft } from 'react-icons/bs'
import { RiNotification3Line } from 'react-icons/ri'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { TooltipComponent } from '@syncfusion/ej2-react-popups'

import avatar from '../data/userprofile.png'
import { Cart, Chat, Notifications, UserProfile } from '.'
import { useStateContext } from '../context/ContextProvider'

const Navbutton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position='BottomCenter'>
    <button type="button" onClick={customFunc} style={{ color }} className="relative text-xl rounded-full p-3 transform transition duration-100 hover:scale-110">
      <span style={{ background: dotColor }} className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2" />{icon}
    </button>
  </TooltipComponent>
)

const Navbar = () => {

  const { activeMenu, setActiveMenu, isClicked, setIsClicked, handleClick, screenSize, setScreenSize, currentUser, credits } = useStateContext()
  const [isUserMenuActive, setIsUserMenuActive] = useState(false)

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth)
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize)
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    }
    else {
      setActiveMenu(true)
    }
  }, [screenSize])


  let menuRef = useRef()

  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (!menuRef.current.contains(event.target)) {
        setIsUserMenuActive(false)
      }
    })
  })

  return (
    <div className='fixed flex justify-between p-2 md:px-6 relative bg-sidebar-bg text-white'>
      <div>
        <div>
        </div>
        <div className='lg:hidden'>
          <Navbutton
            title="Menu"
            customFunc={() => setActiveMenu((prevActiveMenu) => (!prevActiveMenu))}
            color='white'
            icon={<AiOutlineMenu />}
          />
        </div>
      </div>
      <div className='flex align-items-center justify-center'>
        {/* <Navbutton 
        title="Cart" 
        customFunc={() => handleClick('cart')} 
        color='white' 
        icon={<FiShoppingCart />}
      /> */}
        {/* <Navbutton
          title="Chat"
          dotColor="#03C9D7"
          customFunc={() => handleClick('chat')}
          color='white'
          icon={<BsChatLeft />}
        />
        <Navbutton
          title="Notifications"
          dotColor="#03C9D7"
          customFunc={() => handleClick('notification')}
          color='white'
          icon={<RiNotification3Line />}
        /> */}
        <div className='flex justify-center items-center mr-3'>
          {/* <p><span className='font-bold'>Credits:</span> {credits}</p> */}
        </div>
        <TooltipComponent content="Profile" position="BottomCenter">
          <div ref={menuRef}>
            <div className='flex items-center gap-2 cursor-pointer p-1 rounded-lg' onClick={() => setIsUserMenuActive((prevActiveMenu) => (!prevActiveMenu))}>
              <img src={currentUser.photoURL ? currentUser.photoURL : avatar} className="rounded-full w-8 h-8" />
              <p>
                <span className='text-gray-400 text-14 transform transition duration-500 hover:scale-110'>V.0.1</span>{' '}
                {/* <span className='text-gray-400 font-bold ml-1 text-14 transform transition duration-500 hover:scale-110'>{currentUser.displayName ? currentUser.displayName : currentUser.email}</span> */}
              </p>
              <MdKeyboardArrowDown className='text-gray-400 text-14' />
            </div>
            {isUserMenuActive && <UserProfile />}
          </div>
        </TooltipComponent>

        {/* {isClicked.cart && <Cart/>}
      {isClicked.chat && <Chat/>}
      {isClicked.notification && <Notifications/>} */}


      </div>
    </div>
  )
}

export default Navbar